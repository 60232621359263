import isEmpty from "lodash/isEmpty";
import { Option } from "../../types/common";
import { parameterize } from "../url";

interface ListingSearchProps {
  selectedSuburbs?: string[];
  keywords?: string;
  minPrice?: string;
  maxPrice?: string;
  selectedCategories?: Option[];
  locationState?: Option;
  selectedRegions?: Option[];
  newListing?: boolean;
  status?: string;
}

interface AgencySearchProps {
  selectedSuburbs?: string[];
  keywords?: string;
  locationState?: Option;
  selectedRegions?: Option[];
}

interface AgentSearchProps {
  selectedSuburbs?: string[];
  keywords?: string;
  selectedCategories?: Option[];
  locationState?: Option;
}

// /accessories-parts+acai-brothers-superfood-for-sale/vic+melbourne--vic-3000+glen-waverley--vic-3150+bellarine-peninsula+eastern-melbourne?price_max=$200,000&price_min=$50,000&query=foo
// /business-for-sale/vic+melbourne--vic-3000+glen-waverley--vic-3150+bellarine-peninsula+eastern-melbourne?price_max=$200,000&price_min=$50,000&query=foo
// /business-for-sale/vic+melbourne--vic-3000+glen-waverley--vic-3150?price_max=$200,000&price_min=$50,000&query=foo
// /business-for-sale/vic?price_max=$200,000&price_min=$50,000&query=foo
// /accessories-parts+acai-brothers-superfood-for-sale?price_max=$200,000&price_min=$50,000&query=foo
// /acai-brothers-superfood+accessories-parts-for-sale/?price_max=Any&price_min=Any&query=Croc%27s%20Playcentre
// /accessories-parts+accommodation-tourism-for-sale/qld+mount-delaney--qld-4514+mount-kilcoy--qld-4515+central-region+gold-coast?price_max=Any&price_min=Any

export const listingSearchURL = ({
  selectedCategories,
  selectedSuburbs,
  keywords,
  minPrice,
  maxPrice,
  locationState,
  selectedRegions,
  newListing,
  status,
}: ListingSearchProps): string => {
  let params = [];
  let categoryStr = '';
  let locationStr = ''
  let locationStrs = [];
  if (locationState?.label) {
    locationStrs.push(locationState.label.toLowerCase());
  }
  if (!isEmpty(selectedSuburbs)) {
    locationStrs = locationStrs.concat(selectedSuburbs.map(hint => parameterize(hint)));
  }
  if (!isEmpty(selectedRegions)) {
    locationStrs = locationStrs.concat(selectedRegions.map(({label}) => parameterize(label)));
  }
  locationStr = locationStrs.join('+');
  if (!isEmpty(selectedCategories)) {
    categoryStr = selectedCategories.map(({label}) => parameterize(label)).join('+');
  }
  if (!isEmpty(keywords)) {
    const sanitizedKeywords = keywords.toLowerCase();
    if (categoryStr.length === 0) {
      categoryStr = sanitizedKeywords;
    }
    else if (categoryStr.length > 0) {
      params.unshift(`query=${sanitizedKeywords}`);
    }
  }
  if (!isEmpty(minPrice)) {
    if (minPrice === "$ (min)") {
      minPrice = "Any";
    }
    params.unshift(`price_min=${minPrice}`);
  }
  if (!isEmpty(maxPrice)) {
    if (maxPrice === "$ (max)") {
      maxPrice = "Any";
    }
    params.unshift(`price_max=${maxPrice}`);
  }
  if (newListing) {
    params.unshift(`is_new=${newListing}`);
  }
  if (status) {
    params.unshift(`status=${status}`);
  }
  if (categoryStr.length === 0) {
    categoryStr = 'business';
  }
  categoryStr += '-for-sale';
  return `/${[categoryStr, locationStr].join('/')}?${params.join("&")}`;
};

export const agencySearchURL = ({
  selectedSuburbs,
  keywords,
  locationState,
  selectedRegions,
}: AgencySearchProps): string => {
  let params = [];
  if (selectedSuburbs && selectedSuburbs.length > 0) {
    selectedSuburbs.map((suburb) =>
      params.unshift(`search[suburb_hint][]=${suburb}`)
    );
  }
  if (!isEmpty(keywords)) {
    params.unshift(`search[query]=${keywords}`);
  }
  if (locationState && locationState.value) {
    params.unshift(`search[state_id]=${locationState.value}`);
  }
  if (selectedRegions && selectedRegions.length > 0) {
    selectedRegions.map((region) => {
      params.unshift(`search[region_ids][]=${region.value}`);
    });
  }
  return `/search-agencies?${params.join("&")}`;
};

export const agentSearchURL = ({
  selectedSuburbs,
  selectedCategories,
  keywords,
  locationState,
}: AgentSearchProps): string => {
  let params = [];
  if (selectedSuburbs && selectedSuburbs.length > 0) {
    selectedSuburbs.map((suburb) =>
      params.unshift(`search[suburb_hint][]=${suburb}`)
    );
  }
  if (keywords && keywords.length > 0) {
    params.unshift(`search[query]=${keywords}`);
  }
  if (selectedCategories && selectedCategories.length > 0) {
    selectedCategories.map((cat) => {
      params.unshift(`search[child_category_ids][]=${cat.value}`);
    });
  }
  if (locationState && locationState.value) {
    params.unshift(`search[state_id]=${locationState.value}`);
  }
  return `/search-brokers?${params.join("&")}`;
};

export const placeholderColor = () =>
  ["/", "/business-agencies", "/business-brokers"].includes(
    window.location.pathname
  )
    ? "#fff"
    : "#888";

export const selectedOptsShownOnTop = (options, defaultOptions) => {
  if (isEmpty(defaultOptions)) {
    return options;
  }
  defaultOptions.forEach((el) => {
    const index = options.findIndex((opt) => {
      return opt.value === el.value;
    });
    if (index !== -1) {
      options.unshift(options.splice(index, 1)[0]);
    }
  });
  return options;
};

export const seoPagination = () => {
  const lastPageLink = document.querySelector(".uk-pagination>li:last-child");
  if (lastPageLink) {
    lastPageLink.remove();
  }
  const paginationEllipsis = document.querySelectorAll(".uk-pagination>li.disabled");
  if (paginationEllipsis.length > 0) {
    paginationEllipsis.forEach(i => i.remove());
  }
}
