import React from "react";
import Select, { components } from "react-select";
import get from "lodash/get";
import { placeholderColor, selectedOptsShownOnTop } from "../../lib/search";
import { Option } from '../../types/common';

interface Props {
  selectOptions: (selectedOpts: Option[]) => void;
  defaultOptions?: Option[];
  locationState?: number;
}

const options = {
  "23": [
    { label: "Bayside", value: 249 },
    { label: "Bellarine Peninsula", value: 250 },
    { label: "Eastern Melbourne", value: 251 },
    { label: "Gippsland", value: 252 },
    { label: "Inner East Melbourne", value: 253 },
    { label: "Melbourne City", value: 254 },
    { label: "Melbourne North", value: 255 },
    { label: "Mornington Peninsula", value: 256 },
    { label: "North East Region", value: 257 },
    { label: "North West Melbourne", value: 258 },
    { label: "Northern Region", value: 259 },
    { label: "South East Melbourne", value: 260 },
    { label: "South West Melbourne", value: 261 },
    { label: "South Western Region", value: 262 },
    { label: "Western Melbourne", value: 263 },
    { label: "Western Region", value: 264 },
  ],
  "21": [
    { label: "Brisbane City", value: 218 },
    { label: "Cairns", value: 219 },
    { label: "Central Region", value: 220 },
    { label: "Gold Coast", value: 221 },
    { label: "Inner Brisbane", value: 222 },
    { label: "Northern Brisbane", value: 223 },
    { label: "Northern Region", value: 224 },
    { label: "South East Brisbane", value: 225 },
    { label: "Southern Brisbane", value: 226 },
    { label: "Southern Region", value: 227 },
    { label: "Sunshine Coast", value: 228 },
    { label: "Townsville", value: 229 },
  ],
  "20": [
    { label: "Broken Hill-Dubbo", value: 192 },
    { label: "Canterbury-Bankstown", value: 193 },
    { label: "Central Coast", value: 194 },
    { label: "Central Tablelands", value: 195 },
    { label: "Eastern Suburbs", value: 196 },
    { label: "Hunter Region", value: 197 },
    { label: "Inner West", value: 198 },
    { label: "Liverpool", value: 199 },
    { label: "Lower North Shore", value: 200 },
    { label: "Murray Region", value: 201 },
    { label: "North Coast", value: 202 },
    { label: "Northern Beaches", value: 203 },
    { label: "Parramatta", value: 204 },
    { label: "Riverina Region", value: 205 },
    { label: "South Coast", value: 206 },
    { label: "South Western Sydney", value: 207 },
    { label: "St George", value: 208 },
    { label: "Sutherland Shire", value: 209 },
    { label: "Tamworth", value: 210 },
    { label: "The Hills", value: 211 },
    { label: "Upper North Shore", value: 212 },
    { label: "Western Sydney", value: 213 },
    { label: "Wollongong", value: 214 },
    { label: "Blue Mountains", value: 274 },
    { label: "Regional NSW", value: 275 },
    { label: "Sydney CBD", value: 276 },
  ],
  "19": [
    { label: "Central Coast", value: 265 },
    { label: "Goldfields Region", value: 266 },
    { label: "North East Perth", value: 267 },
    { label: "North West Perth", value: 268 },
    { label: "Northern Region", value: 269 },
    { label: "Perth City", value: 270 },
    { label: "South East Perth", value: 271 },
    { label: "South West Perth", value: 272 },
    { label: "South West Region", value: 273 },
  ],
  "18": [
    { label: "City", value: 230 },
    { label: "Eastern Adelaide", value: 231 },
    { label: "Eyre", value: 232 },
    { label: "Kangaroo Island", value: 233 },
    { label: "Murraylands", value: 234 },
    { label: "Northern", value: 235 },
    { label: "Northern Adelaide", value: 236 },
    { label: "Outer Adelaide", value: 237 },
    { label: "South", value: 238 },
    { label: "Southern Adelaide", value: 239 },
    { label: "Western Adelaide", value: 240 },
    { label: "Yorke and Lower North", value: 241 },
  ],
  "24": [
    { label: "Burnie", value: 242 },
    { label: "Central Hobart", value: 243 },
    { label: "East Coast", value: 244 },
    { label: "East Hobart", value: 245 },
    { label: "Launceston", value: 246 },
    { label: "West Coast", value: 247 },
    { label: "West Hobart", value: 248 },
  ],
  "17": [
    { label: "Darwin", value: 215 },
    { label: "Northern Region", value: 216 },
    { label: "Southern Region", value: 217 },
  ],
  "22": [
    { label: "Gungahlin", value: 186 },
    { label: "Inner North", value: 187 },
    { label: "Inner South", value: 188 },
    { label: "Tuggeranong", value: 189 },
    { label: "Weston Creek", value: 190 },
    { label: "Woden Valley", value: 191 },
    { label: "North", value: 183 },
    { label: "South", value: 184 },
    { label: "Belconnen", value: 185 },
  ],
};

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: "#616161",
    padding: 20,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    svg: {
      fill: "#616161",
    },
    "&:hover": {
      backgroundColor: "hsl(125deg 43% 62%)",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0",
    backgroundColor: "rgba(255,255,255,.1)",
    color: placeholderColor(),
    backgroundClip: "padding-box",
    borderColor: "rgba(255,255,255,.2)",
    minHeight: "40px",
  }),
  container: (provided, state) => ({ ...provided, display: "inline-block" }),
  indicatorSeparator: () => null,
  option: (provided, state) => {
    const backgroundColor = state.isSelected ? "#5db57c" : "none";
    return {
      ...provided,
      backgroundColor,
      ":active": {
        backgroundColor,
      },
    };
  },
};

const renderSelectedText = ({ selectedCount }: { selectedCount: number }) => {
  if (selectedCount === 0) {
    return "Select regions";
  }
  return `${selectedCount} Region${selectedCount != 1 ? "s" : ""}`;
};

const ValueContainer = ({ children, getValue, ...props }) => {
  const selectedCount = getValue().length;

  return (
    <components.ValueContainer {...props}>
      {renderSelectedText({ selectedCount })}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  );
};

const RegionSelect = ({
  selectOptions,
  defaultOptions,
  locationState,
}: Props) => {
  const onChange = (selectedOpts?: Option[]) => {
    selectOptions((selectedOpts || []));
  };

  return (
    <Select
      components={{ ValueContainer }}
      defaultValue={defaultOptions}
      styles={customStyles}
      isMulti
      closeMenuOnSelect={false}
      options={selectedOptsShownOnTop(
        get(options, `${locationState}`, []),
        defaultOptions
      )}
      placeholder="Select categories"
      onChange={onChange}
      hideSelectedOptions={false}
      menuPortalTarget={document.body}
      noOptionsMessage={() => "Select a state first"}
    />
  );
};

export default RegionSelect;
