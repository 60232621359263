import React, { useState } from "react";
import Select from "react-select";
import { StringOption } from "../../types/common";

interface Props {
  options?: StringOption[];
  selectOptions: (selectedOpts: Array<string>) => void;
  defaultOptions?: StringOption[];
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: "#616161",
    padding: 20,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  multiValueRemove: (provided, state) => ({
    ...provided,
    svg: {
      fill: "#616161",
    },
    "&:hover": {
      backgroundColor: "hsl(125deg 43% 62%)",
    },
  }),
};

const SuburbAutoComplete = ({
  selectOptions,
  options,
  defaultOptions,
}: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const placeholder = () => {
    return (
      <div className="uk-inline ellipsis">
        <span data-uk-search-icon></span>
        <span>Search suburb or postcode</span>
      </div>
    );
  };

  const onInputChange = (value: string, { action }: { action: string }) => {
    setMenuIsOpen(false);
    if (action === "input-change" && value.length > 2) {
      setMenuIsOpen(true);
    }
  };

  const onChange = (selectedOpts?: StringOption[]) => {
    const filtered = (selectedOpts || []).slice(-4);
    selectOptions(filtered.map((opt) => opt.value));
  };

  return (
    <Select
      defaultValue={defaultOptions}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={customStyles}
      isMulti
      closeMenuOnSelect={false}
      options={options}
      placeholder={placeholder()}
      menuIsOpen={menuIsOpen}
      onInputChange={onInputChange}
      onChange={onChange}
      menuPortalTarget={document.body}
    />
  );
};

export default SuburbAutoComplete;
