import axios from "axios";

export default function axiosConfig(): void {
  const csrfNode = document.querySelector("meta[name=csrf-token]");
  if (csrfNode) {
    axios.defaults.headers.get["Content-Type"] = "application/json";
    axios.defaults.headers.get["Accept"] = "application/json";
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.post["Accept"] = "application/json";
    axios.defaults.headers.patch["Content-Type"] = "application/json";
    axios.defaults.headers.patch["Accept"] = "application/json";
    axios.defaults.headers.put["Content-Type"] = "application/json";
    axios.defaults.headers.put["Accept"] = "application/json";
    axios.defaults.headers.delete["Content-Type"] = "application/json";
    axios.defaults.headers.delete["Accept"] = "application/json";
    axios.defaults.headers.common["X-CSRF-Token"] = csrfNode.getAttribute(
      "content"
    );
  }
}
