import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { get } from "lodash";
import mount from "../../lib/mount";
import GenericLoader from "../generic_loader";

interface IFormInputs {
  email: string;
  password: string;
  remember_me?: boolean;
}

const LoginForm = () => {
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [submissionError, setSubmissionError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axios
      .post("/users/sign_in", { user: data })
      .then((resp) => {
        window.location.assign(get(resp, "data.location"));
      })
      .catch((error) => {
        setLoading(false);
        setSubmissionError(get(error, "response.data.error"));
      });
  };

  return (
    <div className="uk-card uk-card-default">
      <div className="uk-card-header">
        <h4>Sign in</h4>
      </div>
      <form>
        <div className="uk-card-body">
          {loading && <GenericLoader />}
          <div className="uk-margin">
            <input
              ref={register({ required: "This is required" })}
              className="uk-input"
              type="text"
              name="email"
              placeholder="Enter email"
            />
            {errors.email && (
              <span className="inline-error">{errors.email.message}</span>
            )}
          </div>
          <div className="uk-margin">
            <input
              ref={register({
                required: "This is required",
                minLength: { value: 8, message: "Must be over 7 characters" },
              })}
              className="uk-input"
              type="password"
              name="password"
              placeholder="Enter password"
            />
            {errors.password && (
              <span className="inline-error">{errors.password.message}</span>
            )}
          </div>
          <div>
            <label>
              <input
                ref={register}
                className="uk-checkbox"
                type="checkbox"
                name="remember_me"
              />{" "}
              Remember me
            </label>
          </div>
          <div className="uk-flex uk-flex-column uk-margin-top">
            <a href="/users/sign_up">Sign up</a>
            <a href="/users/password/new">Forgot your password?</a>
          </div>
        </div>
        <div className="uk-card-footer uk-text-center">
          <button
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            className="uk-button uk-button-primary"
          >
            Log in
          </button>
          {submissionError && (
            <span className="inline-error">{submissionError}</span>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoginForm;

mount(LoginForm, "login-form");
