export const CATEGORY_KEYWORDS = [
  "Accessories/Parts",
  "Accommodation/Tourism",
  "Accounting",
  "Adult",
  "Advertising/Mkting",
  "Aerial",
  "Aeronautical",
  "Aged Care",
  "Agricultural",
  "Air",
  "Aircraft",
  "Alarms",
  "Alcohol/Liquor",
  "Amusements",
  "Animal related",
  "Aquaculture",
  "Aquatic/Marine",
  "Arts/Crafts",
  "Auto Electrical",
  "Automotive",
  "Backpacker/Hostel",
  "Bakery",
  "Bars/Nightclubs",
  "Beauty/Health",
  "Beauty Products",
  "Beauty Salon",
  "Bike and Motorcycle",
  "Boarding Kennels",
  "Boats/Marine",
  "Bookkeeping",
  "Brokerage",
  "Building and Construction",
  "Bus",
  "Butcher",
  "Cafe/Coffee Shop",
  "Car",
  "Caravan Park",
  "Car/Bus/Truck",
  "Car Dealership",
  "Car Rental",
  "Car Wash",
  "Catering",
  "Child Care",
  "Cignall",
  "Civil",
  "Cleaning",
  "Clothing/Accessories",
  "Clothing/Footwear",
  "Communication",
  "Communications",
  "Computer/IT",
  "Convenience Store",
  "Copy/Laminate",
  "Courier",
  "Crop Harvesting",
  "Customs",
  "Dairy Farming",
  "Deli",
  "Dental",
  "Detailing",
  "Distributors",
  "Driving Schools",
  "Educational",
  "Education/Training",
  "Electrical",
  "Employment/Recruitment",
  "Entertainment",
  "Entertainment/Tech",
  "Export",
  "Farming",
  "Fertiliser",
  "Finance",
  "Fishing/Forestry",
  "Florist/Nursery",
  "Food/Beverage",
  "Food/Hospitality",
  "Franchise",
  "Freehold/Business",
  "Freight",
  "Fruit Picking",
  "Fruit/Veg",
  "Function Centre",
  "Furniture/Timber",
  "Gambling",
  "Garden/Household",
  "Gardening",
  "Garden/Nurseries",
  "Glass/Ceramic",
  "Guest House/B&B",
  "Hair",
  "Health/Beauty",
  "Health Spa",
  "Hire/Rent",
  "Home Based",
  "Home Based Business",
  "Home/Garden",
  "Homeware/Hardware",
  "Hospital",
  "Hotel",
  "Hunting/Trap",
  "Import",
  "Import/Export/Whole",
  "Industrial/Manufacturing",
  "Insurance",
  "Internet",
  "Investments",
  "Irrigation Services",
  "Juice Bar",
  "Land Clearing",
  "Legal",
  "Leisure/Entertainment",
  "Limousine/Taxi",
  "Livestock",
  "Luggage Sales & Repairs",
  "Machinery",
  "Management Rights",
  "Manufacturers",
  "Manufacturing/Engineering",
  "Marine",
  "Massage",
  "Mechanical Repair",
  "Media",
  "Medical",
  "Mining/Earth Moving",
  "Mobile Services",
  "Motel",
  "Music/Video",
  "Nails",
  "Natural Therapies",
  "Newsagency/Tatts",
  "Nursery",
  "Nursing Home",
  "Office Supplies",
  "Oil/Gas",
  "Online",
  "Other",
  "Panel Beating",
  "Paper/Printing",
  "Parking",
  "Pest related",
  "Pharmacies",
  "Pieface",
  "Plastic",
  "Pool/Water",
  "Post Offices",
  "Print/Photo",
  "Professional",
  "Property/Real Estate",
  "Rail",
  "Recreation/Sport",
  "Recruitment",
  "Repair",
  "Resort",
  "Restaurant",
  "Retail",
  "Retailer",
  "Retirement Village",
  "Road",
  "Rural",
  "Scientific",
  "Sea",
  "Security",
  "Self Storage",
  "Services",
  "Service Station",
  "Shearing",
  "Sports Complex/Gym",
  "Supermarket",
  "Takeaway Food",
  "Taxi",
  "Theme Park",
  "Tours",
  "Training",
  "Transport/Distribution",
  "Travel",
  "Truck",
  "Tyres",
  "Vending",
  "Water",
  "Welding",
  "Wholesale",
  "Wreckers",
];
export const FRANCHISE_KEYWORDS = [
  "Acai Brothers Superfood",
  "Anytime Fitness",
  "Australia Post",
  "Ben & Jerry's",
  "Boost Juice",
  "Brazilian Butterfly",
  "Brumby's",
  "Burgertory",
  "Capricho Grill",
  "Chatime",
  "Chicken Treat",
  "Croc's Playcentre",
  "Crust Pizza",
  "Dominos",
  "Donut King",
  "Dymocks",
  "Fantastic Services",
  "Ferguson Plarre Bakehouses",
  "Freshii",
  "Gloria Jean's Coffees",
  "Greenhouse Asian Salads",
  "Guzman Y Gomez",
  "Harrington Homes",
  "Hot Temple",
  "IGA",
  "IWG",
  "Jetts",
  "Lollipop's Playland",
  "Mathnasium",
  "MBE",
  "Motto Motto",
  "Muffin Break",
  "Nando's",
  "New York Minute",
  "NightOwl",
  "Nurse Next Door",
  "Oporto",
  "Pieface",
  "Pizza Hut",
  "Red Rooster",
  "Refresh Renovations",
  "Right At Home",
  "Sandwich Chefs",
  "Skill Samurai",
  "Smokin' Joe's Pizza",
  "Subway",
  "Sumo Salad",
  "SuperGreen Solutions",
  "Talbot Doors",
  "The Coffee Club",
  "The Little Cha",
  "The Little Gym",
  "The Local Guys",
  "The Lott",
  "The Yiros Shop",
  "Tobacco Station Group",
  "Tutti Frutti",
  "Ultra Tune",
  "Xtend Barre"
];
export const POPULAR_KEYWORDS = [
  "Asian Restaurants",
  "Bakeries",
  "Bars",
  "Beauty Salons",
  "Bottle Shops",
  "Butcher shops",
  "Cake Shops",
  "Caravan Parks",
  "Catering",
  "Chicken Shops",
  "Childcare",
  "Chinese Restaurants",
  "Cleaning",
  "Clothing businesses",
  "Coin Laundry",
  "Delicatessen",
  "Dry cleaning",
  "Electrical",
  "Export",
  "Fabrication businesses",
  "Fish & Chips Shops",
  "Florists",
  "Foodworks",
  "Fruit & Veggie",
  "Gardening",
  "Greek Restaurants",
  "Gyms",
  "Hairdressing",
  "Hire/Rental",
  "Home based",
  "Hotels",
  "IT businesses",
  "Import",
  "Japanese Restaurants",
  "Mechanics",
  "Medical",
  "Metal Businesses",
  "Motels",
  "Online Businesses",
  "Pizza Shops",
  "Pool",
  "Post Offices",
  "RTO businesses",
  "Repairs",
  "Retail clothing",
  "Tattslotto",
  "Vending",
  "Wholesale",
];
