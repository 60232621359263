import React from "react";
import Select, { components } from "react-select";
import { placeholderColor } from "../../lib/search";
import DollarCircle from '../icons/DollarCircle';
import DollarCircleLight from "../icons/DollarCircleLight";
const styles = require("./styles.module.scss");

interface Option {
  value: string;
  label: string;
}

interface Props {
  prefix: string;
  selectOption: (selectedOpt: string) => void;
  defaultOption?: Option;
  dark?: boolean;
}

const options = [
  { label: "Any", value: "Any" },
  { label: "$50,000", value: "$50,000" },
  { label: "$100,000", value: "$100,000" },
  { label: "$150,000", value: "$150,000" },
  { label: "$200,000", value: "$200,000" },
  { label: "$250,000", value: "$250,000" },
  { label: "$300,000", value: "$300,000" },
  { label: "$350,000", value: "$350,000" },
  { label: "$400,000", value: "$400,000" },
  { label: "$450,000", value: "$450,000" },
  { label: "$500,000", value: "$500,000" },
  { label: "$600,000", value: "$600,000" },
  { label: "$700,000", value: "$700,000" },
  { label: "$800,000", value: "$800,000" },
  { label: "$900,000", value: "$900,000" },
  { label: "$1,000,000", value: "$1,000,000" },
  { label: "$2,000,000", value: "$2,000,000" },
  { label: "$3,000,000", value: "$3,000,000" },
  { label: "$4,000,000", value: "$4,000,000" },
  { label: "$5,000,000", value: "$5,000,000" },
  { label: "$10,000,000", value: "$10,000,000" },
];

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: "#616161",
    padding: 20,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: placeholderColor() };
  },
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0",
    backgroundColor: "rgba(255,255,255,.1)",
    color: placeholderColor(),
    backgroundClip: "padding-box",
    borderColor: "rgba(255,255,255,.2)",
    minHeight: "40px",
  }),
  container: (provided, state) => ({ ...provided, display: "inline-block" }),
  indicatorSeparator: () => null,
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#5db57c" : "none",
    ":active": {
      backgroundColor: state.isSelected ? "#5db57c" : "none",
    },
  }),
};

const placeholderTxt = (prefix: string, dark: boolean) => <label className={styles.iconWrapper} >{dark ? <DollarCircle /> : <DollarCircleLight />}{`${prefix} price`}</label>;

const ValueContainer = ({ children, getValue, ...props }) => {
  const selectedPrice = getValue()[0];
  const { placeholder } = props.selectProps;

  return (
    <components.ValueContainer {...props}>
      {!selectedPrice || selectedPrice.label === "Any"
        ? placeholder
        : selectedPrice.label}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  );
};

const PriceSelect = ({ selectOption, prefix, defaultOption, dark }: Props) => {
  const onChange = (selectedOpt: Option) => {
    selectOption(selectedOpt.value);
  };

  return (
    <Select
      defaultValue={defaultOption}
      components={{ ValueContainer }}
      styles={customStyles}
      isSearchable={false}
      className="basic-single"
      classNamePrefix="select"
      name={placeholderTxt(prefix, dark)}
      placeholder={placeholderTxt(prefix, dark)}
      onChange={onChange}
      options={options}
      menuPortalTarget={document.body}
    />
  );
};

export default PriceSelect;
