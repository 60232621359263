import React, { useState } from "react";
import axios from "axios";
import { get } from "lodash";
import mount from "../../lib/mount";
import GenericLoader from "../generic_loader";

const LogoutButton = () => {
  const [loading, setLoading] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .delete("/users/sign_out")
      .then((_) => {
        setLoading(false);
        window.location.assign("/");
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  return (
    <>
      {loading && <GenericLoader />}
      <a href="#" rel="no-follow" onClick={onClick}>
        Log out
      </a>
    </>
  );
};

export default LogoutButton;

mount(LogoutButton, "logout-button");
mount(LogoutButton, "offcanvas-logout-button");
