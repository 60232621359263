// import React from "react";
// import { createRoot } from "react-dom/client";

// export default function mount(Component: React.FC, mountNodeId: string) {
//   document.addEventListener(
//     "DOMContentLoaded",
//     () => {
//       const mountNodes = document.getElementsByClassName(mountNodeId);
//       if (mountNodes.length < 1) {
//         return null;
//       }
//       for (let mountNode of (mountNodes as unknown) as Array<HTMLElement>) {
//         const propsJSON = mountNode.getAttribute("data-react-props");
//         const props = JSON.parse(propsJSON);
//         const root = createRoot(mountNode!);
//         root.render(<Component {...props} />);
//       }
//     },
//     { capture: true }
//   );
// }
import React from "react";
import ReactDOM from "react-dom";

export default function mount(Component: React.FC, mountNodeId: string) {
  document.addEventListener("DOMContentLoaded", () => {
    const mountNodes = document.getElementsByClassName(mountNodeId);
    if (mountNodes.length < 1) {
      return null;
    }
    for (let mountNode of mountNodes as any) {
      const propsJSON = mountNode.getAttribute("data-react-props");
      const props = JSON.parse(propsJSON);

      ReactDOM.render(<Component {...props} />, mountNode);
    }
  });
}
