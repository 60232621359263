import { capitalize, findKey } from "lodash";
import { Option } from "../../types/common";

const activeColor = "#5db57c";
const color = "#888";
export const placeholderColor = "#666";

interface CategoryApiProp {
  id: number;
  name: string;
}

export const customStyles = {
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity, color };
  },
  input: (provided, state) => ({
    ...provided,
    color,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : color,
    backgroundColor: state.isSelected ? activeColor : "none",
    ":active": {
      backgroundColor: state.isSelected ? activeColor : "none",
    },
  }),
};

export const string2Option = (str: string): Option => ({
  label: capitalize(str),
  value: str,
});

export const array2Options = (array: string[]): Option[] => {
  return array.map((i) => ({ label: i, value: i }));
};

export const json2Options = (json: object): Option[] => {
  return Object.entries(json).map(([label, value]) => ({ label, value }));
};

export const findOptionFromJsonByValue = (value: any, json: object): Option => {
  return { label: findKey(json, (o) => o === value), value };
};

export const categoryOptionsFromApi = (array: CategoryApiProp[]): Option[] => {
  return array.map(({ name, id }) => ({ label: name, value: id }));
};
