import React, { useState, useEffect } from "react";
import axios from "axios";
import LocationStateSelect from "../../location_state_select";
import SuburbAutoComplete from "../../suburb_autocomplete";
import mount from "../../../lib/mount";
import { agentSearchURL } from "../../../lib/search";
import GenericLoader from "../../generic_loader";
import CategorySelect from "../../category_select";
import { DEFAULT_SUBURB_OPTIONS } from "../../../lib/consts";

const styles = require("./styles.module.scss");

export const AgentSearch = () => {
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState(null);
  const [locationState, setLocationState] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSuburbs, setSelectedSuburbs] = useState([]);
  const [suburbs, setSuburbs] = useState(DEFAULT_SUBURB_OPTIONS);

  useEffect(() => {
    axios
      .get("/api/suburbs?hint=value&has_listings=true")
      .then((res) => {
        setSuburbs(res.data.suburbs);
      })
      .catch((error) => {
        console.log(`api/suburbs returns ${error}`);
      });
  }, []);

  const search = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    window.location.assign(
      agentSearchURL({
        selectedSuburbs,
        keywords,
        locationState,
        selectedCategories,
      })
    );
  };

  return (
    <div className="uk-flex-wrap">
      <div className={`uk-inline ${styles.suburbInput}`}>
        <SuburbAutoComplete
          options={suburbs}
          selectOptions={setSelectedSuburbs}
        />
      </div>
      <button
        onClick={search}
        className={`uk-button uk-button-default ${styles.desktopButton}`}
      >
        Search
      </button>
      <div className="uk-flex uk-flex-column uk-margin">
        <div className={`uk-flex uk-flex-wrap ${styles.formFieldWrapper}`}>
          <input
            autoComplete="off"
            className="uk-input"
            type="text"
            placeholder="Broker's name"
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
          />
          <LocationStateSelect selectOption={setLocationState} />
          <CategorySelect selectOptions={setSelectedCategories} />
        </div>
        <div className={`uk-flex uk-flex-wrap ${styles.placeholder}`}></div>
      </div>
      {loading && <GenericLoader />}
      <button
        onClick={search}
        className={`uk-button uk-button-default ${styles.mobileButton}`}
      >
        Search
      </button>
    </div>
  );
};

mount(AgentSearch, "agent-search");
