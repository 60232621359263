export const queryString = (urlQueryString = window.location.search) =>
  urlQueryString.substring(1);

export const urlToParams = (decoded = true) => {
  const s = queryString();

  const params = s.split("&").reduce((memo, param) => {
    const [k, v] = param.split("=");
    if (decoded) {
      memo[decodeURIComponent(k)] = urlArrayValues(
        memo[decodeURIComponent(k)],
        decodeURIComponent(v)
      );
    } else {
      memo[k] = urlArrayValues(memo[k], v);
    }
    return memo;
  }, {});

  return params;
};

/**
 * Handle array params e.g. search[child_category_ids][]=1
 */
export const urlArrayValues = (existingValue?: string, newValue?: string) => {
  if (existingValue) {
    return Array(existingValue).flat().concat(newValue);
  }
  return newValue;
};

export const parameterize = (str) => (str.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/g, "-").replace(/\s/g, "-"))
  .replace(/^-/, '')
  .replace(/-$/, '');
