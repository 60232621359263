// import "core-js/stable";
// import "regenerator-runtime/runtime";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import axiosConfig from "../lib/axios_config";
axiosConfig();
UIkit.use(Icons);

// Listings

import "../components/listings/search";
import "../components/listings/carousel/recent";

// Agencies

import "../components/agencies/search";

// Me

import "../components/me/profile";

// Tabs
import "../components/search_tabs";

// Auth

import "../components/login_form";
import "../components/sign_up_form";
import "../components/logout_button";
