import axios from "axios";
import React, { useEffect, useState } from "react";
import { Listing } from "../../../types/common";

const listingImageUrl = (listing) => {
  if (!listing || listing.images.length === 0) {
    return null;
  }
  return listing.images[listing.images.length - 1]?.file?.standard?.url.replace('q_auto', 'q_auto,e_trim')
}

const agencyLogoUrl = (listing) => {
  return listing?.agency?.logo_file?.url;
}

const Carousel = (): JSX.Element => {
  const [listings, setListings] = useState<Listing[]>([])
  useEffect(() => {
    axios
      .get(`/api/listings/recent`)
      .then(({ data }) => {
        setListings(data.listings);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [])

  if (listings.length === 0) {
    return null;
  }

  return (
    <div className="uk-slider-container">
      <ul className="uk-slider-items uk-flex">
        {
          listings.map((listing) => {
            return (
              <li style={{ marginRight: '15px', paddingBottom: '5px' }}>
                <div className="uk-card uk-border-rounded uk-box-shadow-medium" style={{ width: '220px', height: '280px' }}>
                  <a href={listing?.url}>
                    <div className="uk-card-media-top">
                      <div style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px', padding: '5px', fontSize: '14px', lineHeight: '18px', backgroundColor: listing?.agency?.primary_color, height: '40px' }}><img src={agencyLogoUrl(listing)} style={{ height: '40px', width: '220px', objectFit: 'contain' }} /></div>
                      <img src={listingImageUrl(listing)} style={{ height: '150px', width: '220px', objectFit: 'cover' }} />
                    </div>
                    <div style={{ display: 'flex', fontSize: '14px', padding: '5px', justifyContent: 'space-between' }}>
                      <div><b>{listing?.price_view}</b></div>
                      <div>{listing?.address_to_consumers}</div>
                    </div>
                    <div className="uk-text-truncate" style={{ padding: '0 5px 0 5px', fontSize: '14px', lineHeight: '19px' }}>{listing.title}</div>
                  </a>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Carousel;
