import React from "react";
const styles = require("./styles.module.scss");

interface Props {
  extraClassName?: string;
  showOverlay?: boolean;
  center?: boolean;
}

const GenericLoader = ({
  extraClassName,
  showOverlay,
  center = true,
}: Props) => (
  <div
    className={`${extraClassName} ${styles.loader__wrapper} ${
      center ? styles.center : ""
    } ${showOverlay ? styles.showOverlay : ""}`}
  >
    <div className={styles.loader}>Loading...</div>
  </div>
);

export default GenericLoader;
