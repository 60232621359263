export const DEFAULT_SUBURB_OPTIONS = [
  { label: "MELBOURNE, VIC 3000", value: "MELBOURNE, VIC 3000" },
  { label: "SYDNEY, NSW 2000", value: "SYDNEY, NSW 2000" },
  { label: "BRISBANE, QLD 4000", value: "BRISBANE, QLD 4000" },
  { label: "ADELAIDE, SA 5000", value: "ADELAIDE, SA 5000" },
  { label: "PERTH, WA 6000", value: "PERTH, WA 6000" },
  { label: "HOBART, TAS 7000", value: "HOBART, TAS 7000" },
  { label: "DARWIN, NT 0800", value: "DARWIN, NT 0800" },
  { label: "CANBERRA, ACT 2600", value: "CANBERRA, ACT 2600" },
];
