import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { StringOption } from "../../types/common";

interface Props {
  options?: StringOption[];
  selectOptions: (selectedOpts: Array<string>) => void;
  defaultOptions?: StringOption[];
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: "#616161",
    padding: 20,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  multiValueRemove: (provided, state) => ({
    ...provided,
    svg: {
      fill: "#616161",
    },
    "&:hover": {
      backgroundColor: "hsl(125deg 43% 62%)",
    },
  }),
};

const SuburbAutocompleteAsync = ({ selectOptions, defaultOptions }: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const placeholder = () => {
    return (
      <div className="uk-inline ellipsis">
        <span data-uk-search-icon></span>
        <span>Search suburb or postcode</span>
      </div>
    );
  };

  const onChange = (selectedOpts?: StringOption[]) => {
    setMenuIsOpen(false);
    const filtered = (selectedOpts || []).slice(-4);
    selectOptions(filtered.map((opt) => opt.value));
  };

  const fetchData = (inputValue: string): StringOption[] => {
    return axios
      .get(`/api/suburbs?hint=value&suggest=${inputValue}`)
      .then((res) => {
        return res.data.suburbs;
      })
      .catch((error) => {
        console.log(`api/suburbs returns ${error}`);
        return [];
      });
  };

  const filterOptions = (inputValue: string) => {
    if (inputValue.length < 3) {
      setMenuIsOpen(false);
      return [];
    }

    setMenuIsOpen(true);

    const results = fetchData(inputValue);
    return results;
  };

  const asyncOptions = (inputValue: string) =>
    new Promise<StringOption[]>((resolve) => {
      const results = filterOptions(inputValue);
      // if (results.length > 0) {
      //   setMenuIsOpen(true);
      // }
      resolve(filterOptions(inputValue));
    });

  return (
    <AsyncSelect
      defaultValue={defaultOptions}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={customStyles}
      isMulti
      closeMenuOnSelect={false}
      loadOptions={asyncOptions}
      placeholder={placeholder()}
      onChange={onChange}
      menuIsOpen={menuIsOpen}
      menuPortalTarget={document.body}
      noOptionsMessage={({ inputValue }) =>
        !inputValue ? "Type suburb or postcode above" : ""
      }
    />
  );
};

export default SuburbAutocompleteAsync;
