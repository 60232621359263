import React, { useEffect, useState } from "react";
import axios from "axios";
import Select, { components } from "react-select";
import { placeholderColor, selectedOptsShownOnTop } from "../../lib/search";
import { categoryOptionsFromApi } from "../../lib/form";
import { Option } from "../../types/common";

interface Props {
  selectOptions: (selectedOpts: Array<Option>) => void;
  defaultOptions?: Option[];
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: "#616161",
    padding: 20,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    svg: {
      fill: "#616161",
    },
    "&:hover": {
      backgroundColor: "hsl(125deg 43% 62%)",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0",
    backgroundColor: "rgba(255,255,255,.1)",
    color: placeholderColor(),
    backgroundClip: "padding-box",
    borderColor: "rgba(255,255,255,.2)",
    minHeight: "40px",
  }),
  container: (provided, state) => ({ ...provided, display: "inline-block" }),
  indicatorSeparator: () => null,
  // indicatorsContainer: (provided, state) => {
  //   const { selectProps: { menuIsOpen } } = state;

  //   const indicatorColour = "#cccccc";
  //   return {
  //     ...provided,
  //     color: indicatorColour,
  //   }
  // },
  option: (provided, state) => {
    const backgroundColor = state.isSelected ? "#5db57c" : "none";
    return {
      ...provided,
      backgroundColor,
      ":active": {
        backgroundColor,
      },
    };
  },
};

const renderSelectedText = ({ selectedCount, menuIsOpen }: { selectedCount: number, menuIsOpen: boolean }) => {
  if (selectedCount === 0 && menuIsOpen) {
    return null;
  }
  if (selectedCount === 0 && !menuIsOpen) {
    return "Select categories";
  }
  return `${selectedCount} ${selectedCount > 1 ? "categories" : "category"} selected`;
};

const ValueContainer = ({ children, getValue, ...props }) => {
  const selectedCount = getValue().length;
  const { menuIsOpen } = props.selectProps;

  return (
    <components.ValueContainer {...props}>
      {renderSelectedText({ selectedCount, menuIsOpen })}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  );
};

const CategorySelect = ({ selectOptions, defaultOptions }: Props) => {
  const [categories, setCategories] = useState([
    { label: "Supermarket", value: 463 },
    { label: "Homeware/Hardware", value: 535 },
    { label: "Professional", value: 509 },
    { label: "Convenience Store", value: 455 },
    { label: "Home/Garden", value: 466 },
    { label: "Building and Construction", value: 479 },
    { label: "Automotive", value: 415 },
    { label: "Leisure/Entertainment", value: 494 },
    { label: "Accommodation/Tourism", value: 401 },
    { label: "Beauty/Health", value: 431 },
    { label: "Industrial/Manufacturing", value: 478 },
    { label: "Franchise", value: 397 },
    { label: "Retailer", value: 462 },
    { label: "Services", value: 561 },
    { label: "Food/Beverage", value: 533 },
    { label: "Restaurant", value: 461 },
    { label: "Cafe/Coffee Shop", value: 453 },
    { label: "Takeaway Food", value: 464 },
    { label: "Retail", value: 528 },
    { label: "Food/Hospitality", value: 450 },
  ]);

  useEffect(() => {
    axios
      .get("/api/categories")
      .then((res) => {
        setCategories(categoryOptionsFromApi(res.data.categories));
      })
      .catch((error) => {
        console.log(`api/categories returns ${error}`);
      });
  }, []);

  const onChange = (selectedOpts?: Option[]) => {
    selectOptions((selectedOpts || []));
  };

  return (
    <Select
      components={{ ValueContainer }}
      defaultValue={defaultOptions}
      styles={customStyles}
      isMulti
      closeMenuOnSelect={false}
      options={selectedOptsShownOnTop(categories, defaultOptions)}
      placeholder="Select categories"
      onChange={onChange}
      hideSelectedOptions={false}
      menuPortalTarget={document.body}
    />
  );
};

export default CategorySelect;
