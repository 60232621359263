import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { get } from "lodash";
import mount from "../../lib/mount";
import GenericLoader from "../generic_loader";

interface IFormInputs {
  email: string;
  first_name: string;
  password: string;
  password_confirmation: string;
}

const SignUpForm = () => {
  const { register, handleSubmit, errors, setError } = useForm<IFormInputs>();
  const [submissionError, setSubmissionError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: IFormInputs) => {
    if (data.password_confirmation != data.password) {
      return setError("password_confirmation", {
        type: "manual",
        message: "Password confirmation doesn't match",
      });
    }
    setLoading(true);
    await axios
      .post("/users", { user: data })
      .then((_) => {
        window.location.assign("/");
      })
      .catch((error) => {
        setLoading(false);
        setError("email", {
          type: "manual",
          message: get(error, "response.data.errors.email[0]"),
        });
      });
  };

  return (
    <div className="uk-card uk-card-default">
      <div className="uk-card-header">
        <h4>Sign up</h4>
      </div>
      <form>
        <div className="uk-card-body">
          {loading && <GenericLoader />}
          <div className="uk-margin">
            <input
              ref={register({ required: "This is required" })}
              className="uk-input"
              type="text"
              name="email"
              placeholder="Enter email"
            />
            {errors.email && (
              <span className="inline-error">{errors.email.message}</span>
            )}
          </div>
          <div className="uk-margin">
            <input
              ref={register({ required: "This is required" })}
              className="uk-input"
              type="text"
              name="first_name"
              placeholder="Enter name"
            />
            {errors.first_name && (
              <span className="inline-error">{errors.first_name.message}</span>
            )}
          </div>
          <div className="uk-margin">
            <input
              ref={register({
                required: "This is required",
                minLength: { value: 8, message: "Must be over 7 characters" },
              })}
              className="uk-input"
              type="password"
              name="password"
              placeholder="Enter password"
            />
            {errors.password && (
              <span className="inline-error">{errors.password.message}</span>
            )}
          </div>
          <div className="uk-margin">
            <input
              ref={register({
                required: "This is required",
                minLength: { value: 8, message: "Must be over 7 characters" },
              })}
              className="uk-input"
              type="password"
              name="password_confirmation"
              placeholder="Confirm password"
            />
            {errors.password_confirmation && (
              <span className="inline-error">
                {errors.password_confirmation.message}
              </span>
            )}
          </div>
          <div className="uk-flex uk-flex-column uk-margin-top">
            <a href="/users/sign_in">Already have an account? Login</a>
            <a href="/users/password/new">Forgot your password?</a>
          </div>
        </div>
        <div className="uk-card-footer uk-text-center">
          <button
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            className="uk-button uk-button-primary"
          >
            Sign up
          </button>
          {submissionError && (
            <span className="inline-error">{submissionError}</span>
          )}
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;

mount(SignUpForm, "sign-up-form");
