import React from "react";
import Select, { components } from "react-select";
import { placeholderColor } from "../../lib/search";
import { Option } from "../../types/common";

interface Props {
  selectOption: (selectedOpt: Option) => void;
  defaultOption?: Option;
}

const options = [
  { label: "All states", value: 0 },
  { label: "VIC", value: 23 },
  { label: "QLD", value: 21 },
  { label: "NSW", value: 20 },
  { label: "WA", value: 19 },
  { label: "SA", value: 18 },
  { label: "TAS", value: 24 },
  { label: "NT", value: 17 },
  { label: "ACT", value: 22 },
];

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: "#616161",
    padding: 20,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: placeholderColor() };
  },
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0",
    backgroundColor: "rgba(255,255,255,.1)",
    color: placeholderColor(),
    backgroundClip: "padding-box",
    borderColor: "rgba(255,255,255,.2)",
    minHeight: "40px",
  }),
  container: (provided, state) => ({ ...provided, display: "inline-block" }),
  indicatorSeparator: () => null,
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#5db57c" : "none",
    ":active": {
      backgroundColor: state.isSelected ? "#5db57c" : "none",
    },
  }),
};

const ValueContainer = ({ children, getValue, ...props }) => {
  const selectedState = getValue()[0];
  const { placeholder } = props.selectProps;

  return (
    <components.ValueContainer {...props}>
      {!selectedState || selectedState.label === "All states"
        ? placeholder
        : selectedState.label}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  );
};

const LocationStateSelect = ({ selectOption, defaultOption }: Props) => {
  const onChange = (selectedOpt: Option) => {
    selectOption(selectedOpt);
  };

  return (
    <Select
      defaultValue={defaultOption}
      components={{ ValueContainer }}
      styles={customStyles}
      isSearchable={false}
      className="basic-single"
      classNamePrefix="select"
      name="state"
      placeholder="Select state"
      onChange={onChange}
      options={options}
      menuPortalTarget={document.body}
    />
  );
};

export default LocationStateSelect;
