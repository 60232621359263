import React from "react";
import mount from "../../lib/mount";
import { Tab } from "../../types/common";
import { Search } from "../listings/search";
import { AgencySearch } from "../agencies/search";
import { AgentSearch } from "../agents/search";
const styles = require("./styles.module.scss");

interface Props extends React.HTMLProps<JSX.Element> {
  tabs: Tab[];
}

export const SearchTabs = ({ tabs }: Props) => {
  const lookupTabComp = (key: string) => {
    switch (key) {
      case "listing-search":
        return <Search />;
      case "agency-search":
        return <AgencySearch />;
      case "agent-search":
        return <AgentSearch />;
      default:
        return null;
    }
  };

  return (
    <>
      <ul
        className={`uk-child-width-expand uk-tab ${styles.tabs}`}
        uk-switcher=""
      >
        {tabs.map((tab) => (
          <li key={`tab-name-${tab.key}`}>
            <a href="#">{tab.name}</a>
          </li>
        ))}
      </ul>
      <ul className="uk-switcher">
        {tabs.map((tab) => (
          <li key={`tab-comp-${tab.key}`}>
            <form>{lookupTabComp(tab.key)}</form>
          </li>
        ))}
      </ul>
    </>
  );
};

mount(SearchTabs, "search-tabs");
