import React, { useState } from "react";
import { highlight } from "../../lib/strings";
import {
  CATEGORY_KEYWORDS,
  FRANCHISE_KEYWORDS,
  POPULAR_KEYWORDS,
} from "./consts";

const styles = require("./styles.module.scss");

interface KeywordAutocompleteProps {
  keywords?: string;
  setKeywords: (selectedOpt: string) => void;
}

interface SuggestionProps {
  suggestions: Array<string>;
  label?: string;
}

const KeywordAutocomplete = ({
  keywords,
  setKeywords,
}: KeywordAutocompleteProps) => {
  const [panelOpen, setPanelOpen] = useState(false);

  const onChange = (inputValue: string) => {
    setPanelOpen(true);
    setKeywords(inputValue);
  };

  const renderSuggestionPanel = () => {
    if (!keywords || keywords.length < 2) {
      return null;
    }

    const suggestedCategories = CATEGORY_KEYWORDS.filter((keyword) => {
      return keyword.toLowerCase().includes(keywords.toLowerCase());
    });

    const suggestedFranchises = FRANCHISE_KEYWORDS.filter((keyword) => {
      return keyword.toLowerCase().includes(keywords.toLowerCase());
    });

    const suggestedPopular = POPULAR_KEYWORDS.filter((keyword) => {
      return keyword.toLowerCase().includes(keywords.toLowerCase());
    });

    const onSelect = (suggestedKeyword: string) => {
      setKeywords(suggestedKeyword);
      setPanelOpen(false);
    };

    const renderSuggestions = ({ suggestions, label }: SuggestionProps) => {
      if (suggestions.length === 0) {
        return null;
      }

      return (
        <>
          <label>{label}</label>
          <ul>
            {suggestions.map((suggestedKeyword, index) => {
              return (
                <li
                  key={`${suggestedKeyword}${index}`}
                  onClick={() => onSelect(suggestedKeyword)}
                  dangerouslySetInnerHTML={{
                    __html: highlight({
                      haystack: suggestedKeyword,
                      needle: keywords,
                    }),
                  }}
                />
              );
            })}
          </ul>
        </>
      );
    };

    return (
      <div className={styles.suggestionPanel}>
        {renderSuggestions({
          suggestions: suggestedFranchises,
          label: "Franchises:",
        })}
        {renderSuggestions({
          suggestions: suggestedCategories,
          label: "Categories:",
        })}
        {renderSuggestions({
          suggestions: suggestedPopular,
          label: "Popular:",
        })}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <input
        autoComplete="off"
        className="uk-input"
        type="text"
        placeholder="Keywords or business ID"
        onChange={(e) => onChange(e.target.value)}
        value={keywords || ""}
      />
      {panelOpen && renderSuggestionPanel()}
    </div>
  );
};

export default KeywordAutocomplete;
