import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { notification } from "uikit";
import axios from "axios";
import { get } from "lodash";
import GenericLoader from "../../generic_loader";
import mount from "../../../lib/mount";

interface IFormInputs {
  name: string;
  email: string;
  status: string;
  mobile: string;
}

const Profile = () => {
  const { register, handleSubmit, errors } = useForm<IFormInputs>();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [status, setStatus] = useState(null);
  const [subscribed, setSubscribed] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/me/profile")
      .then((resp) => {
        setLoading(false);
        const data = get(resp, "data.data");
        setName(data.name);
        setEmail(data.email);
        setMobile(data.mobile);
        setStatus(data.status);
        setSubscribed(data.subscribed);
      })
      .catch(() => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> Cannot process your request, please try again later`,
          pos: "top-center",
          status: "danger",
        });
      });
  }, []);

  const onSubmit = (data: IFormInputs) => {
    setLoading(true);
    axios
      .patch("/api/me/profile/", { ...data, status })
      .then(() => {
        setLoading(false);
        notification({
          message: '<span uk-icon="icon: check"></span> Profile updated',
          pos: "top-center",
          status: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        notification({
          message: `<span uk-icon=\"icon: warning\"></span> ${get(
            error,
            "response.data.error"
          )}`,
          pos: "top-center",
          status: "danger",
        });
      });
  };

  //   {status && <div className="uk-margin uk-grid uk-flex-middle">
  //   <label className="uk-width-1-4">Status</label>
  //   <Select
  //     className="uk-width-expand@m"
  //     defaultValue={string2Option(status)}
  //     isSearchable={false}
  //     onChange={(selectedOpt: Option) => setStatus(selectedOpt.value)}
  //     options={array2Options(["active", "inactive"])}
  //   />
  // </div>}

  return (
    <div className="uk-container-xxxsmall uk-margin-auto">
      <div className="uk-card uk-card-default">
        <div className="uk-card-header">
          <h4 className="uk-text-center">My profile</h4>
        </div>
        <form>
          <div className="uk-card-body">
            {loading && <GenericLoader />}
            <div className="uk-margin">
              <input
                ref={register({ required: "This is required" })}
                className="uk-input"
                type="text"
                name="name"
                placeholder="Enter name"
                defaultValue={name}
              />
              {errors.name && (
                <span className="inline-error">{errors.name.message}</span>
              )}
            </div>
            <div className="uk-margin">
              <input
                ref={register({ required: "This is required" })}
                className="uk-input"
                type="text"
                name="email"
                placeholder="Enter email"
                defaultValue={email}
              />
              {errors.email && (
                <span className="inline-error">{errors.email.message}</span>
              )}
            </div>
            <div className="uk-margin">
              <input
                ref={register({ required: "This is required" })}
                className="uk-input"
                type="text"
                name="mobile"
                placeholder="Enter mobile"
                defaultValue={mobile}
              />
              {errors.mobile && (
                <span className="inline-error">{errors.mobile.message}</span>
              )}
            </div>
            <div className="uk-margin">
              <label>
                <input
                  ref={register}
                  className="uk-checkbox uk-margin-small-right"
                  type="checkbox"
                  name="subscribed"
                  defaultChecked={subscribed}
                />
                Receive email alerts
              </label>
            </div>
          </div>
          <div className="uk-card-footer uk-text-center">
            <button
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
              className="uk-button uk-button-primary"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;

mount(Profile, "my-profile");
