interface Props {
  needle: string;
  haystack: string;
}

export const highlight = ({ needle, haystack }: Props): string =>
  haystack.replace(
    new RegExp(needle, "gi"),
    (str) => `<strong class="highlighted-substr">${str}</strong>`
  );
