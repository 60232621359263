import React, { useState, useEffect } from "react";
import axios from "axios";
import SuburbAutoComplete from "../../suburb_autocomplete";
import LocationStateSelect from "../../location_state_select";
import mount from "../../../lib/mount";
import { agencySearchURL } from "../../../lib/search";
import GenericLoader from "../../generic_loader";
import RegionSelect from "../../region_select";
import { DEFAULT_SUBURB_OPTIONS } from "../../../lib/consts";

const styles = require("./styles.module.scss");

export const AgencySearch = () => {
  const [loading, setLoading] = useState(false);
  const [selectedSuburbs, setSelectedSuburbs] = useState([]);
  const [keywords, setKeywords] = useState(null);
  const [locationState, setLocationState] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [suburbs, setSuburbs] = useState(DEFAULT_SUBURB_OPTIONS);

  useEffect(() => {
    axios
      .get("/api/suburbs?hint=value&has_listings=true")
      .then((res) => {
        setSuburbs(res.data.suburbs);
      })
      .catch((error) => {
        console.log(`api/suburbs returns ${error}`);
      });
  }, []);

  const search = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    window.location.assign(
      agencySearchURL({
        selectedSuburbs,
        keywords,
        locationState,
        selectedRegions,
      })
    );
  };

  return (
    <div className="uk-flex-wrap">
      <div className="uk-flex">
        <div className={`uk-inline ${styles.suburbInput}`}>
          <SuburbAutoComplete
            options={suburbs}
            selectOptions={setSelectedSuburbs}
          />
        </div>
        <button
          onClick={search}
          className={`uk-button uk-button-default ${styles.desktopButton}`}
        >
          Search
        </button>
      </div>
      <div className="uk-flex uk-flex-column uk-margin">
        <div className={`uk-flex uk-flex-wrap ${styles.formFieldWrapper}`}>
          <input
            autoComplete="off"
            className="uk-input"
            type="text"
            placeholder="Agency's name"
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
          />
          <div className={styles.locationStateWrapper}>
            <LocationStateSelect selectOption={setLocationState} />
          </div>
          <RegionSelect
            locationState={locationState}
            selectOptions={setSelectedRegions}
          />
        </div>
        <div className={`uk-flex uk-flex-wrap ${styles.placeholder}`}></div>
      </div>
      {loading && <GenericLoader />}
      <button
        onClick={search}
        className={`uk-button uk-button-default ${styles.mobileButton}`}
      >
        Search
      </button>
    </div>
  );
};

mount(AgencySearch, "agency-search");
