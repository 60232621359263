import React from "react";

const DollarCircle = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.08317 12.2222C7.08317 13.2961 7.95373 14.1667 9.02762 14.1667H10.8332C11.9838 14.1667 12.9165 13.2339 12.9165 12.0833C12.9165 10.9327 11.9838 10 10.8332 10H9.1665C8.01591 10 7.08317 9.06727 7.08317 7.91667C7.08317 6.76608 8.01591 5.83334 9.1665 5.83334H10.9721C12.0459 5.83334 12.9165 6.7039 12.9165 7.77778M9.99984 4.58334V5.83334M9.99984 14.1667V15.4167M18.3332 10C18.3332 14.6024 14.6022 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6024 1.6665 10C1.6665 5.39763 5.39746 1.66667 9.99984 1.66667C14.6022 1.66667 18.3332 5.39763 18.3332 10Z" stroke="black" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default DollarCircle;
