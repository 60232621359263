import React, { useState } from "react";
import SuburbAutocompleteAsync from "../../suburb_autocomplete_async";
import CategorySelect from "../../category_select";
import PriceSelect from "../../price_select";
import LocationStateSelect from "../../location_state_select";
import RegionSelect from "../../region_select";
import mount from "../../../lib/mount";
import { listingSearchURL } from "../../../lib/search";
import GenericLoader from "../../generic_loader";
import { Option } from "../../../types/common";
import KeywordAutocomplete from "../../keyword_autocomplete";

const styles = require("./styles.module.scss");

// not used since suburb_autocomplete_all performs async autocomplete
interface Props extends React.HTMLProps<JSX.Element> {
  suburbs?: Option[];
}

export const Search = ({ suburbs }: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedSuburbs, setSelectedSuburbs] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [minPrice, setMinPrice] = useState("Any");
  const [maxPrice, setMaxPrice] = useState("Any");
  const [locationState, setLocationState] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [newListing, setNewListing] = useState(false);
  const [status, setStatus] = useState(null);

  const search = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    window.location.assign(
      listingSearchURL({
        selectedSuburbs,
        keywords,
        minPrice,
        maxPrice,
        selectedCategories,
        locationState,
        selectedRegions,
        newListing,
        status,
      })
    );
  };

  return (
    <div className="uk-flex-wrap">
      <div className="uk-flex">
        <div className={`uk-inline ${styles.suburbInput}`}>
          <SuburbAutocompleteAsync selectOptions={setSelectedSuburbs} />
        </div>
        <button
          onClick={search}
          className={`uk-button uk-button-default ${styles.desktopButton}`}
        >
          Search
        </button>
      </div>
      <div className="uk-flex uk-flex-column uk-margin">
        <div className={`uk-flex uk-flex-wrap ${styles.formFieldWrapper}`}>
          <div className={styles.categoryWrapper}>
            <KeywordAutocomplete
              keywords={keywords}
              setKeywords={setKeywords}
            />
          </div>
          <div className={styles.categoryWrapper}>
            <CategorySelect selectOptions={setSelectedCategories} />
          </div>
        </div>
        <div className={`uk-flex uk-flex-wrap ${styles.formFieldWrapper}`}>
          <LocationStateSelect selectOption={setLocationState} />
          <RegionSelect
            locationState={locationState?.value}
            selectOptions={setSelectedRegions}
          />
          <PriceSelect prefix="min" selectOption={setMinPrice} />
          <PriceSelect prefix="max" selectOption={setMaxPrice} />
        </div>
        <div className={`uk-flex uk-flex-wrap ${styles.formFieldWrapper} ${styles.checkboxGroup}`}>
          <label><input className="uk-checkbox uk-margin-small-right" type="checkbox" checked={!!newListing} onChange={()=>{setNewListing(!newListing)}}/>New listings only</label>
          <label><input className="uk-checkbox uk-margin-small-right" type="checkbox" checked={status === "sold"} onChange={()=>{setStatus(status === "sold" ? null : "sold")}}/>Sold listings</label>
        </div>
        <div
          className={`uk-flex uk-flex-wrap ${styles.formFieldWrapper} ${styles.plLink}`}
        >
          <a uk-icon="icon: forward;" href="/private_listings">
            Sell your business or advertise your business for sale
          </a>
        </div>
      </div>
      {loading && <GenericLoader />}
      <button
        onClick={search}
        className={`uk-button uk-button-default ${styles.mobileButton}`}
      >
        Search
      </button>
    </div>
  );
};

mount(Search, "listing-search");
